import { render, staticRenderFns } from "./elevator_item_info_card.vue?vue&type=template&id=539a3621&scoped=true"
import script from "./elevator_item_info_card.vue?vue&type=script&lang=js"
export * from "./elevator_item_info_card.vue?vue&type=script&lang=js"
import style0 from "./elevator_item_info_card.vue?vue&type=style&index=0&id=539a3621&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539a3621",
  null
  
)

export default component.exports